<template>
	<v-form @submit="submitForm" class="contact-form" v-model="isFormValid">
		<div class="form-group">
			<label>Name</label>
			<v-text-field
				:disabled="isFormSubmitting"
				:loading="isFormSubmitting"
				:rules="nameRule"
				v-model="name"
				placeholder="Please provide your name"
			></v-text-field>
		</div>
		<div class="form-group">
			<label>Email</label>
			<v-text-field
				:disabled="isFormSubmitting"
				:loading="isFormSubmitting"
				:rules="emailRule"
				v-model="email"
				placeholder="Please provide your email"
			></v-text-field>
		</div>
		<div class="form-group">
			<label>Description</label>
			<v-textarea
				:disabled="isFormSubmitting"
				:loading="isFormSubmitting"
				:rules="descriptionRules"
				auto-grow
				rows="1"
				v-model="description"
				placeholder="Briefly tell me how I can help you?"
			></v-textarea>
		</div>
		<v-btn fab color="primary" :disabled="isFormSubmitting" type="submit" :loading="isFormSubmitting">
			<v-icon>mdi-send</v-icon>
		</v-btn>
	</v-form>
</template>

<script>
import { contactRequestCollection } from "../store/firebase";
export default {
	data: () => {
		return {
			isFormValid: true,
			isFormSubmitting: false,
			name: "",
			email: "",
			inquiredServices: [],
			description: "",
			descriptionRules: [(v) => !!v || "Description is required"],
			nameRule: [(v) => !!v || "Name is required"],
			emailRule: [(v) => !!v || "Email is required"],
		};
	},
	methods: {
		submitForm(event) {
			event.preventDefault();
			this.$data.isFormSubmitting = true;
			contactRequestCollection
				.add({
					name: this.$data.name,
					email: this.$data.email,
					description: this.$data.description,
				})
				.then(() => {
					this.$emit("submitted");
				})
				.catch(() => {});
		},
	},
};
</script>

<style lang="scss">
.contact-form {
	width: 100%;
}
</style>