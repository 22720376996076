<template>
	<v-container class="add-contact-container">
		<v-row>
			<v-col cols="12" class="heading text-center">{{ title }}</v-col>
			<v-col
				v-if="!isFormSubmitted"
				cols="12"
				sm="10"
				md="6"
				lg="4"
				xl="3"
				class="ml-auto mr-auto d-flex align-center"
			>
				<ContactRequestForm :isFormSubmitted="isFormSubmitted" @submitted="formSubmitted" />
			</v-col>
			<v-col md="6" xl="4" v-if="showIllustration || isFormSubmitted" class="ml-auto mr-auto">
				<ContactMeIllustration />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import ContactMeIllustration from "../assets/Illustrations/Contact Me Illustration.svg";
import ContactRequestForm from "../components/ContactRequestForm";
export default {
	data: () => {
		return {
			isFormSubmitted: false,
			isFormGoing: false,
		};
	},
	components: {
		ContactMeIllustration,
		ContactRequestForm,
	},
	methods: {
		formSubmitted() {
			this.$data.isFormGoing = true;
			setTimeout(
				function () {
					this.$data.isFormSubmitted = true;
				}.bind(this),
				600
			);
		},
	},
	computed: {
		title() {
			if (this.$data.isFormSubmitted) {
				return "I'll get back to you soon";
			} else {
				return "Contact Me!";
			}
		},
		showIllustration() {
			console.log(this.$vuetify.breakpoint.name);
			return (
				this.$vuetify.breakpoint.name !== "xs" && this.$vuetify.breakpoint.name !== "sm"
			);
		},
	},
};
</script>

<style lang="scss">
.add-contact-container {
	margin-top: auto;
	margin-bottom: auto;
}
</style>